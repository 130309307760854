.App {
  text-align: center;
}
 *{
  font-family: sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.btn-flat {
background-color: purple;
color: white;
}

.btn-xxl {
padding: 1rem 1.5rem;
font-size: 1.5rem;
}



.btn-outline-main {
  --bs-btn-color: #ff6900;
  --bs-btn-border-color: #ff69004f;
  --bs-btn-hover-color: #fff;
  /* --bs-btn-hover-bg: #ff6900; */
  --bs-btn-hover-border-color: #ff6900;
  --bs-btn-focus-shadow-rgb: 220,53,69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ff69004f;
  --bs-btn-active-border-color: #ff69004f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
  --bs-btn-disabled-color: #ff69004f;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ff69004f;
  --bs-gradient: none;
}

.btn.btn-outline-theme {
  background: #1b9ce3;
  border: 1px solid #1b9ce3;
  color: #fff;
}

.btn.btn-outline-theme:hover {
  border: 1px solid #1b9ce3;
  background: transparent;
  color: #1b9ce3;
}

.btn.btn-outline-theme:active{
color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}
.ftco-navbar-light {
  background: #0d1128 !important;
  z-index: 3;
  padding: 0;
}
.container{
  max-width: 1140px;
}
.nav-link:active,.nav-link:focus  {
  color: #1b9ce3;
}
h2 {
  font-weight: 700;
  line-height: 1.5;
    color: #233e62;
}

body {
  font-family: "Nunito Sans", Arial, sans-serif;
  background: #fff;
  font-size: 16px;
  line-height: 1.8;
  font-weight: 400;
  color: gray;
}
.header-style{
  line-height: 1.2;
  color: black;
}

.carousel-indicators .active {
  opacity: 1;
  background-color: #0d6efd;
}
.footer-links {
  line-height: 2.2;
  font-size: 15px;
}
.menu-social a {
  color:#fff
}
.menu-social a:hover {
  color: #1b9ce3;
}
.ftco-navbar-light .navbar-nav > .nav-link:hover{
  color: grey;
}

.mod-head{
  border:none;
  padding-bottom:0
}

.lang a{
  font-weight: 400;
    color: #fff;
    opacity: 1 !important;
    text-decoration: underline;
}