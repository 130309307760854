/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
 :root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }
  
  *,
  *::before,
  *::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  
  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  
   figcaption, figure, footer, header, main, nav, section {
    display: block; }
  
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff; }
  
  [tabindex="-1"]:focus {
    outline: 0 !important; }
  
  hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible; }
  
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem; }
  
  p {
    margin-top: 0;
    margin-bottom: 1rem; }
  
  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit; }
  
  ol,
  ul {
    margin-top: 0;
    margin-bottom: 1rem; }
  
  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0; }
  
  dt {
    font-weight: 700; }
  
  b,
  strong {
    font-weight: bolder; }
  
  small {
    font-size: 80%; }
  
  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline; }
  
  sub {
    bottom: -.25em; }
  
  sup {
    top: -.5em; }
  
  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent; }
    a:hover {
      color: #0056b3;
      text-decoration: underline; }
  
  a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none; }
    a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
      color: inherit;
      text-decoration: none; }
    a:not([href]):not([tabindex]):focus {
      outline: 0; }
  
  pre,
  code {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em; }
  
  pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto; }
  
  figure {
    margin: 0 0 1rem; }
  
  img {
    vertical-align: middle;
    border-style: none; }
  
  svg {
    overflow: hidden;
    vertical-align: middle; }
  
  table {
    border-collapse: collapse; }
  
  caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom; }
  
  th {
    text-align: inherit; }
  
  label {
    display: inline-block;
    margin-bottom: 0.5rem; }
  
  button {
    border-radius: 0; }
  
  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color; }
  
  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit; }
  
  button,
  input {
    overflow: visible; }
  
  button,
  select {
    text-transform: none; }
  
  select {
    word-wrap: normal; }
  
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button; }
  
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }
  
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none; }
  
  input[type="radio"],
  input[type="checkbox"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0; }
  
  
  input[type="time"] {
    -webkit-appearance: listbox; }
  
  textarea {
    overflow: auto;
    resize: vertical; }
  
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0; }
  
  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal; }
  
  progress {
    vertical-align: baseline; }
  
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto; }
  
  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none; }
  
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }
  
  output {
    display: inline-block; }
  
  template {
    display: none; }
  
  [hidden] {
    display: none !important; }
  
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2; }
  
  h1, .h1 {
    font-size: 2.5rem; }
  
  h2, .h2 {
    font-size: 2rem; }
  
  h3, .h3 {
    font-size: 1.75rem; }
  
  h4, .h4 {
    font-size: 1.5rem; }
  
  h5, .h5 {
    font-size: 1.25rem; }
  
  h6, .h6 {
    font-size: 1rem; }
  
  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
  
  small,
  .small {
    font-size: 80%;
    font-weight: 400; }
  
  mark,
  .mark {
    padding: 0.2em;
    background-color: #fcf8e3; }
  
  .list-unstyled {
    padding-left: 0;
    list-style: none; }
  
  .img-fluid {
    max-width: 100%;
    height: auto; }
  
  .figure {
    display: inline-block; }
  
  code {
    font-size: 87.5%;
    color: #e83e8c;
    word-break: break-word; }
    a > code {
      color: inherit; }
  
  pre {
    display: block;
    font-size: 87.5%;
    color: #212529; }
    pre code {
      font-size: inherit;
      color: inherit;
      word-break: normal; }
  
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
    @media (min-width: 576px) {
      .container {
        max-width: 540px; } }
    @media (min-width: 768px) {
      .container {
        max-width: 720px; } }
    @media (min-width: 992px) {
      .container {
        max-width: 960px; } }
    @media (min-width: 1200px) {
      .container {
        max-width: 1140px; } }
  
  .container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
  
  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
  
  .no-gutters {
    margin-right: 0;
    margin-left: 0; }
    .no-gutters > .col,
    .no-gutters > [class*="col-"] {
      padding-right: 0;
      padding-left: 0; }
  
   .col, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-12, .col-md, .col-lg-3, .col-lg-4, .col-lg-6, .col-lg-8, .col-lg-9 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; }
  
  .col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  
  @media (min-width: 768px) {
    .col-md {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%; }
    .col-md-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%; }
    .col-md-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .col-md-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .col-md-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%; }
    .col-md-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .col-md-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .col-md-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%; }
    .col-md-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .col-md-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%; }
    .order-md-last {
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13; } }
  
  @media (min-width: 992px) {
    .col-lg-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%; }
    .col-lg-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .col-lg-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%; }
    .col-lg-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .col-lg-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%; }
    .order-lg-last {
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13; } }
  
  .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529; }
    .table th,
    .table td {
      padding: 0.75rem;
      vertical-align: top;
      border-top: 1px solid #dee2e6; }
    .table thead th {
      vertical-align: bottom;
      border-bottom: 2px solid #dee2e6; }
    .table tbody + tbody {
      border-top: 2px solid #dee2e6; }
  
  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
    .form-control::-ms-expand {
      background-color: transparent;
      border: 0; }
    .form-control:focus {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .form-control::-webkit-input-placeholder {
      color: #6c757d;
      opacity: 1; }
    .form-control:-ms-input-placeholder {
      color: #6c757d;
      opacity: 1; }
    .form-control::-ms-input-placeholder {
      color: #6c757d;
      opacity: 1; }
    .form-control::placeholder {
      color: #6c757d;
      opacity: 1; }
    .form-control:disabled, .form-control[readonly] {
      background-color: #e9ecef;
      opacity: 1; }
  
  select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff; }
  
  select.form-control[size], select.form-control[multiple] {
    height: auto; }
  
  textarea.form-control {
    height: auto; }
  
  .form-group {
    margin-bottom: 1rem; }
  
  .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .btn {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
    .btn:hover {
      color: #212529;
      text-decoration: none; }
    .btn:focus, .btn.focus {
      outline: 0;
      -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .btn.disabled, .btn:disabled {
      opacity: 0.65; }
  
  a.btn.disabled,
  fieldset:disabled a.btn {
    pointer-events: none; }
  
  .btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-primary:hover {
      color: #fff;
      background-color: #0069d9;
      border-color: #0062cc; }
    .btn-primary:focus, .btn-primary.focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
    .btn-primary.disabled, .btn-primary:disabled {
      color: #fff;
      background-color: #007bff;
      border-color: #007bff; }
    .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
    .show > .btn-primary.dropdown-toggle {
      color: #fff;
      background-color: #0062cc;
      border-color: #005cbf; }
      .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
      .show > .btn-primary.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  
  .fade {
    -webkit-transition: opacity 0.15s linear;
    -o-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear; }
    @media (prefers-reduced-motion: reduce) {
      .fade {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
    .fade:not(.show) {
      opacity: 0; }
  
  .collapse:not(.show) {
    display: none; }
  
  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition: height 0.35s ease;
    -o-transition: height 0.35s ease;
    transition: height 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
  
  .dropup,
  .dropright,
  .dropdown,
  .dropleft {
    position: relative; }
  
  .dropdown-toggle {
    white-space: nowrap; }
    .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent; }
    .dropdown-toggle:empty::after {
      margin-left: 0; }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem; }
  
  .dropdown-menu-right {
    right: 0;
    left: auto; }
  
  .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem; }
  
  .dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent; }
  
  .dropup .dropdown-toggle:empty::after {
    margin-left: 0; }
  
  .dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem; }
  
  .dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid; }
  
  .dropright .dropdown-toggle:empty::after {
    margin-left: 0; }
  
  .dropright .dropdown-toggle::after {
    vertical-align: 0; }
  
  .dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem; }
  
  .dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: ""; }
  
  .dropleft .dropdown-toggle::after {
    display: none; }
  
  .dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent; }
  
  .dropleft .dropdown-toggle:empty::after {
    margin-left: 0; }
  
  .dropleft .dropdown-toggle::before {
    vertical-align: 0; }
  
  .dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
    right: auto;
    bottom: auto; }
  
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0; }
    .dropdown-item:hover, .dropdown-item:focus {
      color: #16181b;
      text-decoration: none;
      background-color: #f8f9fa; }
    .dropdown-item.active, .dropdown-item:active {
      color: #fff;
      text-decoration: none;
      background-color: #007bff; }
    .dropdown-item.disabled, .dropdown-item:disabled {
      color: #6c757d;
      pointer-events: none;
      background-color: transparent; }
  
  .dropdown-menu.show {
    display: block; }
  
  .nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
  
  .nav-link {
    display: block;
    padding: 0.5rem 1rem; }
    .nav-link:hover, .nav-link:focus {
      text-decoration: none; }
    .nav-link.disabled {
      color: #6c757d;
      pointer-events: none;
      cursor: default; }
  
  .navbar {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.5rem 1rem; }
    .navbar > .container,
    .navbar > .container-fluid {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }
  
  .navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap; }
    .navbar-brand:hover, .navbar-brand:focus {
      text-decoration: none; }
  
  .navbar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
    .navbar-nav .nav-link {
      padding-right: 0;
      padding-left: 0; }
    .navbar-nav .dropdown-menu {
      position: static;
      float: none; }
  
  .navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem; }
    .navbar-toggler:hover, .navbar-toggler:focus {
      text-decoration: none; }
  
  @media (max-width: 991.98px) {
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      padding-right: 0;
      padding-left: 0; } }
  
  @media (min-width: 992px) {
    .navbar-expand-lg {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start; }
      .navbar-expand-lg .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row; }
        .navbar-expand-lg .navbar-nav .dropdown-menu {
          position: absolute; }
        .navbar-expand-lg .navbar-nav .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem; }
      .navbar-expand-lg > .container,
      .navbar-expand-lg > .container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
      .navbar-expand-lg .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto; }
      .navbar-expand-lg .navbar-toggler {
        display: none; } }
  
  .navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
      color: rgba(0, 0, 0, 0.9); }
  
  .navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5); }
    .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
      color: rgba(0, 0, 0, 0.7); }
    .navbar-light .navbar-nav .nav-link.disabled {
      color: rgba(0, 0, 0, 0.3); }
  
  .navbar-light .navbar-nav .show > .nav-link,
  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9); }
  
  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1); }
  
  .navbar-dark .navbar-brand {
    color: #fff; }
    .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
      color: #fff; }
  
  .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5); }
    .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
      color: rgba(255, 255, 255, 0.75); }
    .navbar-dark .navbar-nav .nav-link.disabled {
      color: rgba(255, 255, 255, 0.25); }
  
  .navbar-dark .navbar-nav .show > .nav-link,
  .navbar-dark .navbar-nav .active > .nav-link,
  .navbar-dark .navbar-nav .nav-link.show,
  .navbar-dark .navbar-nav .nav-link.active {
    color: #fff; }
  
  .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1); }
  
  .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem; }
  
  @-webkit-keyframes progress-bar-stripes {
    from {
      background-position: 1rem 0; }
    to {
      background-position: 0 0; } }
  
  @keyframes progress-bar-stripes {
    from {
      background-position: 1rem 0; }
    to {
      background-position: 0 0; } }
  
  .progress {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem; }
  
  .media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1; }
  
  .list-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0; }
  
  .list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125); }
    .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem; }
    .list-group-item:last-child {
      margin-bottom: 0;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem; }
    .list-group-item.disabled, .list-group-item:disabled {
      color: #6c757d;
      pointer-events: none;
      background-color: #fff; }
    .list-group-item.active {
      z-index: 2;
      color: #fff;
      background-color: #007bff;
      border-color: #007bff; }
  
  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5; }
    .close:hover {
      color: #000;
      text-decoration: none; }
    .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
      opacity: .75; }
  
  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
  
  a.close.disabled {
    pointer-events: none; }
  
  .toast {
    max-width: 350px;
    overflow: hidden;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: 0;
    border-radius: 0.25rem; }
    .toast:not(:last-child) {
      margin-bottom: 0.75rem; }
    .toast.showing {
      opacity: 1; }
    .toast.show {
      display: block;
      opacity: 1; }
    .toast.hide {
      display: none; }
  
  .modal-open {
    overflow: hidden; }
    .modal-open .modal {
      overflow-x: hidden;
      overflow-y: auto; }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0; }
  
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none; }
    .modal.fade .modal-dialog {
      -webkit-transition: -webkit-transform 0.3s ease-out;
      transition: -webkit-transform 0.3s ease-out;
      -o-transition: transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
      transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
      -webkit-transform: translate(0, -50px);
      -ms-transform: translate(0, -50px);
      transform: translate(0, -50px); }
      @media (prefers-reduced-motion: reduce) {
        .modal.fade .modal-dialog {
          -webkit-transition: none;
          -o-transition: none;
          transition: none; } }
    .modal.show .modal-dialog {
      -webkit-transform: none;
      -ms-transform: none;
      transform: none; }
  
  .modal-dialog-scrollable {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-height: calc(100% - 1rem); }
    .modal-dialog-scrollable .modal-body {
      overflow-y: auto; }
  
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000; }
    .modal-backdrop.fade {
      opacity: 0; }
    .modal-backdrop.show {
      opacity: 0.5; }
  
  .modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem; }
  
  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll; }
  
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto; }
    .modal-dialog-scrollable {
      max-height: calc(100% - 3.5rem); } }
  
  .tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0; }
    .tooltip.show {
      opacity: 0.9; }
    .tooltip .arrow {
      position: absolute;
      display: block;
      width: 0.8rem;
      height: 0.4rem; }
      .tooltip .arrow::before {
        position: absolute;
        content: "";
        border-color: transparent;
        border-style: solid; }
  
  .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem; }
  
  .popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem; }
    .popover .arrow {
      position: absolute;
      display: block;
      width: 1rem;
      height: 0.5rem;
      margin: 0 0.3rem; }
      .popover .arrow::before, .popover .arrow::after {
        position: absolute;
        display: block;
        content: "";
        border-color: transparent;
        border-style: solid; }
  
  .popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px); }
    .popover-header:empty {
      display: none; }
  
  .popover-body {
    padding: 0.5rem 0.75rem;
    color: #212529; }
  
  .carousel {
    position: relative; }
  
  .carousel.pointer-event {
    -ms-touch-action: pan-y;
    touch-action: pan-y; }
  
  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    transition: -webkit-transform 0.6s ease-in-out;
    -o-transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-item {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
  
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    display: block; }
/*   
  .carousel-item-next:not(.carousel-item-left),
  .active.carousel-item-right {
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%); }
  
  .carousel-item-prev:not(.carousel-item-right),
  .active.carousel-item-left {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%); } */
  
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none; }
    .carousel-indicators li {
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      width: 30px;
      height: 3px;
      margin-right: 3px;
      margin-left: 3px;
      text-indent: -999px;
      cursor: pointer;
      background-color: #fff;
      background-clip: padding-box;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      opacity: .5;
      -webkit-transition: opacity 0.6s ease;
      -o-transition: opacity 0.6s ease;
      transition: opacity 0.6s ease; }
      @media (prefers-reduced-motion: reduce) {
        .carousel-indicators li {
          -webkit-transition: none;
          -o-transition: none;
          transition: none; } }
    .carousel-indicators .active {
      opacity: 1; }
  
  @-webkit-keyframes spinner-border {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg); } }
  
  @keyframes spinner-border {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg); } }
  
  @keyframes spinner-grow {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0); }
    50% {
      opacity: 1; } }
  
  .bg-light {
    background-color: #f8f9fa !important; }
  
  a.bg-light:hover, a.bg-light:focus,
  button.bg-light:hover,
  button.bg-light:focus {
    background-color: #dae0e5 !important; }
  
  .bg-dark {
    background-color: #343a40 !important; }
  
  a.bg-dark:hover, a.bg-dark:focus,
  button.bg-dark:hover,
  button.bg-dark:focus {
    background-color: #1d2124 !important; }
  
  .bg-white {
    background-color: #fff !important; }
  
  .border {
    border: 1px solid #dee2e6 !important; }
  
  .d-block {
    display: block !important; }
  
  .d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  
  @media (min-width: 768px) {
    .d-md-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important; } }
  
  .justify-content-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  
  .justify-content-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  
  .justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  
  .align-items-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  
  .align-items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  
  .align-items-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  
  @media (min-width: 768px) {
    .float-md-left {
      float: left !important; } }
  
  .position-static {
    position: static !important; }
  
  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030; }
  
  .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030; }
  
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .sticky-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020; } }
  
  .m-0 {
    margin: 0 !important; }
  
  .mb-0 {
    margin-bottom: 0 !important; }
  
  .m-1 {
    margin: 0.25rem !important; }
  
  .mt-2 {
    margin-top: 0.5rem !important; }
  
  .mr-2 {
    margin-right: 0.5rem !important; }
  
  .mb-2 {
    margin-bottom: 0.5rem !important; }
  
  .mt-3 {
    margin-top: 1rem !important; }
  
  .mb-3 {
    margin-bottom: 1rem !important; }
  
  .mt-4 {
    margin-top: 1.5rem !important; }
  
  .mr-4 {
    margin-right: 1.5rem !important; }
  
  .mb-4 {
    margin-bottom: 1.5rem !important; }
  
  .mt-5 {
    margin-top: 3rem !important; }
  
  .mr-5 {
    margin-right: 3rem !important; }
  
  .mb-5 {
    margin-bottom: 3rem !important; }
  
  .p-0 {
    padding: 0 !important; }
  
  
  .px-0 {
    padding-right: 0 !important; }
  
  .pl-0,
  .px-0 {
    padding-left: 0 !important; }
  
  .p-2 {
    padding: 0.5rem !important; }
  
  
  .py-2 {
    padding-top: 0.5rem !important; }
  
  .pb-2,
  .py-2 {
    padding-bottom: 0.5rem !important; }
  
  
  .py-3 {
    padding-top: 1rem !important; }
  
  
  .px-3 {
    padding-right: 1rem !important; }
  
  
  .py-3 {
    padding-bottom: 1rem !important; }
  
  .pl-3,
  .px-3 {
    padding-left: 1rem !important; }
  
  .p-4 {
    padding: 1.5rem !important; }
  
  
  .py-4 {
    padding-top: 1.5rem !important; }
  
  
  .px-4 {
    padding-right: 1.5rem !important; }
  
  
  .py-4 {
    padding-bottom: 1.5rem !important; }
  
  .pl-4,
  .px-4 {
    padding-left: 1.5rem !important; }
  
  .p-5 {
    padding: 3rem !important; }
  
  .pt-5,
  .py-5 {
    padding-top: 3rem !important; }
  
  
  .px-5 {
    padding-right: 3rem !important; }
  
  
  .py-5 {
    padding-bottom: 3rem !important; }
  
  
  .px-5 {
    padding-left: 3rem !important; }
  
  .mr-auto {
    margin-right: auto !important; }
  
  .ml-auto {
    margin-left: auto !important; }
  
  @media (min-width: 768px) {
    .mb-md-0 {
      margin-bottom: 0 !important; }
    .ml-md-4 {
      margin-left: 1.5rem !important; }
    .mb-md-5 {
      margin-bottom: 3rem !important; }
    .pt-md-0 {
      padding-top: 0 !important; }
    
    .py-md-4 {
      padding-top: 1.5rem !important; }
    .pr-md-4 {
      padding-right: 1.5rem !important; }
    
    .py-md-4 {
      padding-bottom: 1.5rem !important; }
    
    .py-md-5 {
      padding-top: 3rem !important; }
    
    .px-md-5 {
      padding-right: 3rem !important; }
    
    .py-md-5 {
      padding-bottom: 3rem !important; }
    
    .px-md-5 {
      padding-left: 3rem !important; } }
  
  @media (min-width: 992px) {
    .pl-lg-5 {
      padding-left: 3rem !important; } }
  
  .text-center {
    text-align: center !important; }
  
  .font-weight-bold {
    font-weight: 700 !important; }
  
  .visible {
    visibility: visible !important; }
  
  @media print {
    *,
    *::before,
    *::after {
      text-shadow: none !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important; }
    a:not(.btn) {
      text-decoration: underline; }
    pre {
      white-space: pre-wrap !important; }
    pre {
      border: 1px solid #adb5bd;
      page-break-inside: avoid; }
    thead {
      display: table-header-group; }
    tr,
    img {
      page-break-inside: avoid; }
    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3; }
    h2,
    h3 {
      page-break-after: avoid; }
    @page {
      size: a3; }
    body {
      min-width: 992px !important; }
    .container {
      min-width: 992px !important; }
    .navbar {
      display: none; }
    .table {
      border-collapse: collapse !important; }
      .table td,
      .table th {
        background-color: #fff !important; } }
  
  body {
    font-family: "Nunito Sans", Arial, sans-serif;
    background: #fff;
    font-size: 16px;
    line-height: 1.8;
    font-weight: 400;
    color: gray; }
  
  a {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    color: #1b9ce3; }
    a:hover, a:focus {
      text-decoration: none;
      color: #1b9ce3;
      outline: none !important; }
  
  h1, h2, h3, h4, h5,
  .h1, .h2, .h3, .h4, .h5 {
    line-height: 1.5;
    color: #233e62;
    font-weight: 400; }
  
  .topper {
    font-size: 15px;
    width: 100%;
    display: block; }
    @media (max-width: 767.98px) {
      .topper {
        margin-bottom: 10px; } }
    .topper .icon {
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%; }
      .topper .icon span {
        color: #1b9ce3;
        font-size: 20px; }
    .topper .text {
      color: rgba(0, 0, 0, 0.7);
      padding-left: 15px; }
      .topper .text span {
        width: 100%;
        display: block;
        line-height: 1; }
        .topper .text span:first-child {
          font-weight: 700;
          color: #000000; }
    .topper .d-block {
      width: 100%; }
    .topper .btn {
      width: 100%; }
  
  .searchform {
    height: 54px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px; }
    .searchform .form-control {
      width: calc(100% - 52px);
      border: none;
      background: transparent !important;
      color: rgba(255, 255, 255, 0.7) !important;
      font-size: 16px; }
      .searchform .form-control::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(255, 255, 255, 0.7) !important; }
      .searchform .form-control::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(255, 255, 255, 0.7) !important; }
      .searchform .form-control:-ms-input-plac255h255l255er {
        /* IE 0+ */
        color: rgba(255, 255, 255, 0.7) !important; }
      .searchform .form-control:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(255, 255, 255, 0.7) !important; }
    .searchform .search {
      width: 52px;
      height: 52px; }
      .searchform .search span {
        font-size: 20px; }
  
  .ftco-navbar-light {
    background: #0d1128 !important;
    z-index: 3;
    padding: 0; }
    @media (max-width: 991.98px) {
      .ftco-navbar-light {
        background: #233e62 !important;
        position: relative;
        top: 0;
        padding: 10px 15px; } }
    .ftco-navbar-light .navbar-brand {
      color: #fff; }
      .ftco-navbar-light .navbar-brand:hover, .ftco-navbar-light .navbar-brand:focus {
        color: #fff; }
      @media (max-width: 991.98px) {
        .ftco-navbar-light .navbar-brand {
          color: #fff; } }
    @media (max-width: 991.98px) {
      .ftco-navbar-light .navbar-nav {
        padding-bottom: 10px; } }
    .ftco-navbar-light .navbar-nav > .nav-link {
      font-size: 14px;
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
      padding-left: 20px;
      padding-right: 20px;
      font-weight: 400;
      color: #fff;
      opacity: 1 !important; }
      .ftco-navbar-light .navbar-nav  > .nav-link:hover {
        color: #fff; }
      @media (max-width: 991.98px) {
        .ftco-navbar-light .navbar-nav > .nav-link {
          padding-left: 0;
          padding-right: 0;
          padding-top: .9rem;
          padding-bottom: .9rem;
          color: rgba(255, 255, 255, 0.7); }
          .ftco-navbar-light .navbar-nav  > .nav-link:hover {
            color: #fff; } }
    .ftco-navbar-light .navbar-nav  .dropdown-menu {
      border: none;
      background: #fff;
      -webkit-box-shadow: 0px 10px 34px -20px rgba(0, 0, 0, 0.41);
      -moz-box-shadow: 0px 10px 34px -20px rgba(0, 0, 0, 0.41);
      box-shadow: 0px 10px 34px -20px rgba(0, 0, 0, 0.41); }
      .ftco-navbar-light .navbar-nav  .dropdown-menu .dropdown-item {
        font-size: 14px; }
        .ftco-navbar-light .navbar-nav  .dropdown-menu .dropdown-item:hover, .ftco-navbar-light .navbar-nav >  .dropdown-menu .dropdown-item:focus {
          background: transparent;
          color: #000000; }
    .ftco-navbar-light .navbar-nav  a.active {
      color: #1b9ce3!important; }
      @media (max-width: 991.98px) {
        .ftco-navbar-light .navbar-nav  a.active {
          color: #fff; } }
    .ftco-navbar-light .navbar-toggler {
      border: none;
      color: rgba(255, 255, 255, 0.5) !important;
      cursor: pointer;
      padding-right: 0;
      text-transform: uppercase;
      font-size: 16px;
      letter-spacing: .1em; }
      .ftco-navbar-light .navbar-toggler:focus {
        outline: none !important; }
    .ftco-navbar-light.scrolled {
      position: fixed;
      right: 0;
      left: 0;
      top: 0;
      margin-top: -130px;
      background: #fff !important;
      -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); }
      .ftco-navbar-light.scrolled .nav-item.active > a {
        color: #1b9ce3 !important; }
      @media (max-width: 991.98px) {
        .ftco-navbar-light.scrolled .navbar-nav {
          background: none;
          border-radius: 0px;
          padding-left: 0rem !important;
          padding-right: 0rem !important; } }
      @media (max-width: 767.98px) {
        .ftco-navbar-light.scrolled .navbar-nav {
          background: none;
          padding-left: 0 !important;
          padding-right: 0 !important; } }
      .ftco-navbar-light.scrolled .navbar-toggler {
        border: none;
        color: rgba(0, 0, 0, 0.5) !important;
        border-color: rgba(0, 0, 0, 0.5) !important;
        cursor: pointer;
        padding-right: 0;
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: .1em; }
      .ftco-navbar-light.scrolled .nav-link {
        padding-top: 0.9rem !important;
        padding-bottom: 0.9rem !important;
        color: #000000 !important; }
        .ftco-navbar-light.scrolled .nav-link.active {
          color: #1b9ce3 !important; }
      .ftco-navbar-light.scrolled.awake {
        margin-top: 0px;
        -webkit-transition: .3s all ease-out;
        -o-transition: .3s all ease-out;
        transition: .3s all ease-out; }
      .ftco-navbar-light.scrolled.sleep {
        -webkit-transition: .3s all ease-out;
        -o-transition: .3s all ease-out;
        transition: .3s all ease-out; }
      .ftco-navbar-light.scrolled .navbar-brand {
        color: #000000; }
  
  .navbar-brand {
    font-weight: 800;
    font-size: 30px;
    padding: 0;
    line-height: 1; }
  
  .hero-wrap {
    width: 100%;
    height: 100%;
    position: inherit;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative; }
    .hero-wrap .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      opacity: .9;
      background: #1566ad;
      background: -moz-linear-gradient(left, #1566ad 0%, #1b9de3 100%);
      background: -webkit-gradient(left top, right top, color-stop(0%, #1566ad), color-stop(100%, #1b9de3));
      background: -webkit-linear-gradient(left, #1566ad 0%, #1b9de3 100%);
      background: -o-linear-gradient(left, #1566ad 0%, #1b9de3 100%);
      background: -ms-linear-gradient(left, #1566ad 0%, #1b9de3 100%);
      background: -webkit-gradient(linear, left top, right top, from(#1566ad), to(#1b9de3));
      background: linear-gradient(to right, #1566ad 0%, #1b9de3 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1566ad', endColorstr='#1b9de3', GradientType=1 ); }
    .hero-wrap.hero-wrap-2 {
      height: 300px; }
      .hero-wrap.hero-wrap-2 .slider-text {
        height: 300px; }
        .hero-wrap.hero-wrap-2 .slider-text .bread {
          font-weight: 900;
          color: #fff; }
        .hero-wrap.hero-wrap-2 .slider-text .breadcrumbs {
          text-transform: uppercase;
          font-size: 13px;
          letter-spacing: 1px; }
          .hero-wrap.hero-wrap-2 .slider-text .breadcrumbs span {
            color: #fff;
            border-bottom: 2px solid rgba(255, 255, 255, 0.1); }
            .hero-wrap.hero-wrap-2 .slider-text .breadcrumbs span a {
              color: #fff; }
  
  .owl-carousel {
    position: relative; }
    .owl-carousel .owl-item {
      opacity: .4; }
      .owl-carousel .owl-item.active {
        opacity: 1; }
    .owl-carousel .owl-nav {
      position: absolute;
      top: 50%;
      width: 100%; }
      .owl-carousel .owl-nav .owl-prev,
      .owl-carousel .owl-nav .owl-next {
        position: absolute;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        margin-top: -10px;
        color: #1b9ce3 !important;
        -moz-transition: all 0.7s ease;
        -o-transition: all 0.7s ease;
        -webkit-transition: all 0.7s ease;
        -ms-transition: all 0.7s ease;
        transition: all 0.7s ease;
        opacity: 0; }
        .owl-carousel .owl-nav .owl-prev span:before,
        .owl-carousel .owl-nav .owl-next span:before {
          font-size: 30px; }
      .owl-carousel .owl-nav .owl-prev {
        left: 0; }
      .owl-carousel .owl-nav .owl-next {
        right: 0; }
    .owl-carousel .owl-dots {
      text-align: center; }
      .owl-carousel .owl-dots .owl-dot {
        width: 10px;
        height: 10px;
        margin: 5px;
        border-radius: 50%;
        background: #e6e6e6;
        position: relative; }
        .owl-carousel .owl-dots .owl-dot:hover, .owl-carousel .owl-dots .owl-dot:focus {
          outline: none !important; }
        .owl-carousel .owl-dots .owl-dot.active {
          background: #b3b3b3; }
    .owl-carousel:hover .owl-nav .owl-prev,
    .owl-carousel:hover .owl-nav .owl-next {
      opacity: 1; }
    .owl-carousel:hover .owl-nav .owl-prev {
      left: -25px; }
    .owl-carousel:hover .owl-nav .owl-next {
      right: -25px; }
    .owl-carousel.home-slider {
      position: relative;
      height: 600px;
      z-index: 0; }
      .owl-carousel.home-slider .slider-item {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        height: 600px;
        position: relative;
        z-index: 0; }
        @media (max-width: 1199.98px) {
          .owl-carousel.home-slider .slider-item {
            background-position: center center !important; } }
        .owl-carousel.home-slider .slider-item .overlay {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: #000000;
          opacity: .3; }
        .owl-carousel.home-slider .slider-item .slider-text {
          height: 600px; }
          @media (max-width: 767.98px) {
            .owl-carousel.home-slider .slider-item .slider-text {
              text-align: center; } }
          .owl-carousel.home-slider .slider-item .slider-text .text {
            position: relative; }
          .owl-carousel.home-slider .slider-item .slider-text h1 {
            font-size: 60px;
            color: #fff;
            line-height: 1.2;
            font-weight: 600; }
            .owl-carousel.home-slider .slider-item .slider-text h1 span {
              display: block; }
            @media (max-width: 991.98px) {
              .owl-carousel.home-slider .slider-item .slider-text h1 {
                font-size: 40px; } }
          .owl-carousel.home-slider .slider-item .slider-text .subheading {
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
            color: white;
            letter-spacing: 2px; }
          .owl-carousel.home-slider .slider-item .slider-text p {
            color: rgba(255, 255, 255, 0.9); }
          .owl-carousel.home-slider .slider-item .slider-text .breadcrumbs {
            text-transform: uppercase;
            font-size: 13px;
            letter-spacing: 1px; }
            .owl-carousel.home-slider .slider-item .slider-text .breadcrumbs span {
              border-bottom: 2px solid rgba(255, 255, 255, 0.1); }
              .owl-carousel.home-slider .slider-item .slider-text .breadcrumbs span a {
                color: #fff; }
      .owl-carousel.home-slider .owl-nav {
        position: absolute;
        top: 100%;
        width: 100%; }
        .owl-carousel.home-slider .owl-nav .owl-prev,
        .owl-carousel.home-slider .owl-nav .owl-next {
          position: absolute;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          margin-top: -10px;
          outline: none !important;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          transition: all 0.3s ease;
          opacity: 0; }
          .owl-carousel.home-slider .owl-nav .owl-prev span:before,
          .owl-carousel.home-slider .owl-nav .owl-next span:before {
            font-size: 30px;
            color: rgba(255, 255, 255, 0.5);
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            transition: all 0.3s ease; }
          .owl-carousel.home-slider .owl-nav .owl-prev:hover span:before, .owl-carousel.home-slider .owl-nav .owl-prev:focus span:before,
          .owl-carousel.home-slider .owl-nav .owl-next:hover span:before,
          .owl-carousel.home-slider .owl-nav .owl-next:focus span:before {
            color: #fff; }
        .owl-carousel.home-slider .owl-nav .owl-prev {
          left: 50%;
          margin-left: -80px; }
        .owl-carousel.home-slider .owl-nav .owl-next {
          right: 50%;
          margin-right: -80px; }
      .owl-carousel.home-slider:hover .owl-nav .owl-prev,
      .owl-carousel.home-slider:hover .owl-nav .owl-next {
        opacity: 1; }
      .owl-carousel.home-slider:hover .owl-nav .owl-prev {
        left: 50%;
        margin-left: -80px; }
      .owl-carousel.home-slider:hover .owl-nav .owl-next {
        right: 50%;
        margin-right: -80px; }
      .owl-carousel.home-slider .owl-dots {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 60px;
        width: 100%;
        text-align: center; }
        .owl-carousel.home-slider .owl-dots .owl-dot {
          width: 10px;
          height: 10px;
          margin: 5px;
          border-radius: 50%;
          background: #1b9ce3; }
          .owl-carousel.home-slider .owl-dots .owl-dot.active {
            background: #fff; }
  
  .bg-light {
    background: #fafafa !important; }
  
  .bg-white {
    background: #fff !important; }
  
  .btn {
    cursor: pointer;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    border-radius: 40px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
    .btn:hover, .btn:active, .btn:focus {
      outline: none; }
    .btn.btn-primary {
      background: #1b9ce3;
      border: 1px solid #1b9ce3;
      color: #fff; }
      .btn.btn-primary:hover {
        border: 1px solid #1b9ce3;
        background: transparent;
        color: #1b9ce3; }
    .btn.btn-white {
      background: #fff;
      border: 1px solid #fff;
      color: #1b9ce3; }
      .btn.btn-white:hover, .btn.btn-white:focus, .btn.btn-white:active {
        border: 1px solid #fff;
        color: #1b9ce3; }
  
  .wrap-about {
    position: relative; }
    .wrap-about .wrap-about-border {
      border: 4px solid #d1ebf9;
      padding: 20px; }
    .wrap-about h3 {
      font-weight: 700;
      font-size: 20px; }
    .wrap-about h2 {
      font-weight: 700; }
    .wrap-about .img {
      width: 100%;
      height: 300px;
      margin-bottom: 20px; }
    @media (max-width: 767.98px) {
      .wrap-about {
        margin-bottom: 30px; } }
  
  .services {
    display: block;
    width: 100%;
    position: relative;
    background: #f0f9fd;
    padding: 20px 30px;
    margin-bottom: 30px;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .services:after {
      position: absolute;
      top: 0;
      right: 0;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 50px 50px 0;
      border-color: transparent #fff transparent transparent; }
    .services .icon {
      line-height: 1.3;
      position: relative;
      margin: 0 auto;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%; }
      .services .icon span,.services svg {
        font-size: 50px;
        color: #1b9ce3; }
    @media (max-width: 767.98px) {
      .services .media-body {
        padding-bottom: 3.5em !important; } }
    .services .media-body h3 {
      font-weight: 700;
      font-size: 20px;
      color: #233e62; }
      .active .services, .services:hover {
      background: #1b9ce3;cursor: pointer; }
      .active .services .icon span, .services:hover .icon span,.active .services svg, .services:hover svg {
        cursor: pointer;

        color: #fff; }
        .active .services .media-body, .services:hover .media-body {
        color: rgba(255, 255, 255, 0.9); }
        .active .services .media-body h3, .services:hover .media-body h3 {
          color: #fff; }
  .services p{
    color:grey
  }
  .active .services p{
    color:white
  }
  .services:hover p {
    color:white;
  }
  .services-2 {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    width: 100%;
    padding: 30px;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .services-2.noborder-left {
      border-left: none; }
    .services-2.noborder-bottom {
      border-bottom: none; }
    @media (max-width: 991.98px) {
      .services-2 {
        border-left: none; }
        .services-2.noborder-bottom {
          border-bottom: 1px solid rgba(0, 0, 0, 0.05); } }
    .services-2 .icon span, .services-2 .icon  svg {
      font-size: 50px;
      color: #1b9ce3; }
    .services-2 h3 {
      font-size: 20px;
      font-weight: 700; }
    .services-2:hover {
      background: #1b9ce3;
      -webkit-box-shadow: 0px 0px 27px -16px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 27px -16px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 0px 27px -16px rgba(0, 0, 0, 0.75);
      border: none; }
      .services-2:hover .icon span,.services-2:hover .icon span  svg {
        color: #fff; }
      .services-2:hover .media-body {
        color: rgba(255, 255, 255, 0.9); }
        .services-2:hover .media-body h3 {
          color: #fff; }
  
  .project {
    width: 100%;
    height: 300px;
    margin-bottom: 0;
    position: relative;
    z-index: 0; }
    .project .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      opacity: 0;
      background: #1b9ce3;
      z-index: -1;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease; }
      @media (max-width: 767.98px) {
        .project .overlay {
          opacity: .2; } }
    .project .btn-site {
      position: absolute;
      bottom: 0;
      right: 0;
      bottom: 0;
      text-decoration: none;
      z-index: 1;
      width: 50px;
      height: 50px;
      background: white;
      -moz-transition: all 0.6s ease;
      -o-transition: all 0.6s ease;
      -webkit-transition: all 0.6s ease;
      -ms-transition: all 0.6s ease;
      transition: all 0.6s ease;
      opacity: 0; }
      .project .btn-site span {
        color: #000000;
        font-size: 18px; }
      .project .btn-site:hover {
        background: #1b9ce3; }
    .project .text {
      width: 100%;
      z-index: 1;
      opacity: 0;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease; }
      .project .text h3 {
        font-size: 28px;
        font-weight: 600; }
        .project .text h3 a {
          color: #fff; }
      .project .text span {
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 12px;
        font-weight: 600; }
      @media (max-width: 767.98px) {
        .project .text {
          opacity: 1; }
          .project .text span {
            color: rgba(255, 255, 255, 0.8); } }
    .project:hover .overlay, .project:focus .overlay {
      opacity: .9; }
    .project:hover .text, .project:focus .text {
      opacity: 1; }
    .project:hover .btn-site, .project:focus .btn-site {
      opacity: 1; }
  
  .gallery {
    display: block;
    height: 350px;
    position: relative; }
    .gallery .icon {
      width: 50px;
      height: 50px;
      margin: 0 auto;
      z-index: 0;
      opacity: 0;
      position: relative;
      background: #1b9ce3;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease; }
      .gallery .icon span {
        color: #fff; }
    .gallery:hover .icon, .gallery:focus .icon {
      opacity: 1; }
  
  .block-20 {
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 275px;
    position: relative;
    display: block; }
  
  .blog-entry {
    width: 100%;
    border: 4px solid #d1ebf9; }
    @media (min-width: 768px) {
      .blog-entry {
        margin-bottom: 30px; } }
    @media (max-width: 767.98px) {
      .blog-entry {
        margin-bottom: 30px; } }
    .blog-entry .text {
      position: relative;
      border-top: 0;
      border-radius: 2px; }
      .blog-entry .text .heading {
        font-size: 20px;
        margin-bottom: 16px; }
        .blog-entry .text .heading a {
          color: #000000; }
          .blog-entry .text .heading a:hover, .blog-entry .text .heading a:focus, .blog-entry .text .heading a:active {
            color: #1b9ce3; }
      .blog-entry .text .meta-chat {
        color: #b3b3b3; }
      .blog-entry .text .read {
        color: #000000;
        font-size: 14px; }
    .blog-entry .meta-date {
      display: inline-block;
      background: #1b9ce3; }
      .blog-entry .meta-date span {
        display: block;
        color: #fff; }
      .blog-entry .meta-date .day {
        font-weight: 700;
        font-size: 20px; }
      .blog-entry .meta-date .mos, .blog-entry .meta-date .yr {
        font-size: 13px; }
    .blog-entry .meta > div {
      display: block;
      margin-right: 5px;
      margin-bottom: 0px;
      font-size: 15px; }
      .blog-entry .meta > div a {
        color: #b3b3b3;
        font-size: 13px; }
        .blog-entry .meta > div a:hover {
          color: #cccccc; }
  
  .block-27 ul {
    padding: 0;
    margin: 0; }
    .block-27 ul li {
      display: inline-block;
      margin-bottom: 4px;
      font-weight: 400; }
      .block-27 ul li a, .block-27 ul li span {
        border: 1px solid #bae1f7;
        text-align: center;
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 50%; }
      .block-27 ul li.active a, .block-27 ul li.active span {
        background: #1b9ce3;
        color: #fff;
        border: 1px solid transparent; }
  
  .form-control {
    height: 52px !important;
    background: #fff !important;
    color: #000000 !important;
    font-size: 18px;
    border-radius: 0px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.1); }
    .form-control:focus, .form-control:active {
      border-color: #000000; }
  
  textarea.form-control {
    height: inherit !important; }
  
  .ftco-animate {
    opacity: 0;
    visibility: hidden; }
  
  .about-author .desc h3 {
    font-size: 24px; }
  
  .ftco-section {
    padding: 7em 0;
    position: relative; }
  
  .ftco-no-pt {
    padding-top: 0; }
  
  .ftco-no-pb {
    padding-bottom: 0; }
  
  .ftco-bg-dark {
    background: #3c312e; }
  
  .ftco-footer {
    font-size: 14px;
    background: #232323;
    padding: 6em 0; }
    .ftco-footer .ftco-footer-widget {
      width: 100%;
      display: block; }
      .ftco-footer .ftco-footer-widget h2 {
        font-weight: normal;
        color: #fff;
        margin-bottom: 40px;
        font-size: 20px;
        font-weight: 700; }
      .ftco-footer .ftco-footer-widget ul li a span {
        color: #fff; }
      .ftco-footer .ftco-footer-widget .btn-primary {
        background: #fff !important;
        border: 2px solid #fff !important; }
        .ftco-footer .ftco-footer-widget .btn-primary:hover {
          background: #fff;
          border: 2px solid #fff !important; }
    .ftco-footer p {
      color: rgba(255, 255, 255, 0.7); }
    .ftco-footer a {
      color: rgba(255, 255, 255, 0.7); }
      .ftco-footer a:hover {
        color: #fff; }
    .ftco-footer .ftco-heading-2 {
      font-size: 17px;
      font-weight: 400;
      color: #000000; }
    .ftco-footer .categories li {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
      .ftco-footer .categories li a span {
        color: rgba(255, 255, 255, 0.5) !important; }
    .ftco-footer .subscribe-form .form-group {
      position: relative;
      margin-bottom: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      border-radius: 0; }
      .ftco-footer .subscribe-form .form-group input {
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        -ms-border-radius: 2px;
        border-radius: 2px;
        width: 100%;
        background: rgba(255, 255, 255, 0.1) !important;
        border: none;
        color: rgba(255, 255, 255, 0.7) !important;
        font-size: 16px; }
        .ftco-footer .subscribe-form .form-group input::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: rgba(255, 255, 255, 0.7) !important; }
        .ftco-footer .subscribe-form .form-group input::-moz-placeholder {
          /* Firefox 19+ */
          color: rgba(255, 255, 255, 0.7) !important; }
        .ftco-footer .subscribe-form .form-group input:-ms-input-placeholder {
          /* IE 0+ */
          color: rgba(255, 255, 255, 0.7) !important; }
        .ftco-footer .subscribe-form .form-group input:-moz-placeholder {
          /* Firefox 18- */
          color: rgba(255, 255, 255, 0.7) !important; }
      .ftco-footer .subscribe-form .form-group .submit {
        color: #fff !important;
        font-size: 16px;
        background: #1b9ce3 !important;
        border: none !important;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        -ms-border-radius: 2px;
        border-radius: 2px;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease; }
        .ftco-footer .subscribe-form .form-group .submit:hover {
          cursor: pointer;
          background: #1b9ce3 !important; }
    .ftco-footer .subscribe-form .icon {
      position: absolute;
      top: 50%;
      right: 20px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      color: rgba(255, 255, 255, 0.8); }
    .ftco-footer .block-21 .text .heading {
      font-weight: 400;
      font-size: 16px; }
      .ftco-footer .block-21 .text .heading a {
        color: rgba(255, 255, 255, 0.9); }
        .ftco-footer .block-21 .text .heading a:hover, .ftco-footer .block-21 .text .heading a:active, .ftco-footer .block-21 .text .heading a:focus {
          color: #1b9ce3; }
    .ftco-footer .block-21 .text .meta > div {
      display: inline-block;
      font-size: 12px;
      margin-right: 5px; }
      .ftco-footer .block-21 .text .meta > div a {
        color: gray; }
  
  .ftco-footer-social li {
    list-style: none;
    margin: 0 10px 0 0;
    display: inline-block; }
    .ftco-footer-social li a {
      height: 40px;
      width: 40px;
      display: block;
      float: left;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 50%;
      position: relative; }
      .ftco-footer-social li a span {
        position: absolute;
        font-size: 20px;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
      .ftco-footer-social li a:hover {
        color: #fff; }
  
  #map {
    width: 100%; }
    @media (max-width: 767.98px) {
      #map {
        height: 300px; } }
  
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(27, 156, 227, 0.4);
      -webkit-box-shadow: 0 0 0 0 rgba(27, 156, 227, 0.4);
      box-shadow: 0 0 0 0 rgba(27, 156, 227, 0.4); }
    70% {
      -moz-box-shadow: 0 0 0 30px rgba(27, 156, 227, 0);
      -webkit-box-shadow: 0 0 0 30px rgba(27, 156, 227, 0);
      box-shadow: 0 0 0 30px rgba(27, 156, 227, 0); }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(27, 156, 227, 0);
      -webkit-box-shadow: 0 0 0 0 rgba(27, 156, 227, 0);
      box-shadow: 0 0 0 0 rgba(27, 156, 227, 0); } }
  
  .heading-section .subheading {
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin-bottom: 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.9);
    position: relative; }
  
  .heading-section h2 {
    font-size: 34px;
    font-weight: 700; }
    @media (max-width: 767.98px) {
      .heading-section h2 {
        font-size: 28px; } }
  
  .heading-section.heading-section-white .subheading {
    color: rgba(255, 255, 255, 0.7); }
  
  .heading-section.heading-section-white h2 {
    color: #fff; }
  
  .heading-section.heading-section-white p {
    color: rgba(255, 255, 255, 0.9); }
  
  
  .img,
  .blog-img,
  .user-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }
  
  .testimony-section {
    position: relative; }
    .testimony-section .owl-carousel {
      margin: 0; }
    .testimony-section .owl-carousel .owl-stage-outer {
      padding-top: 4em;
      padding-bottom: 2em;
      position: relative; }
    .testimony-section .owl-nav {
      position: absolute;
      top: 100%;
      width: 100%; }
      .testimony-section .owl-nav .owl-prev,
      .testimony-section .owl-nav .owl-next {
        position: absolute;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        margin-top: -10px;
        outline: none !important;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease;
        opacity: 0; }
        .testimony-section .owl-nav .owl-prev span:before,
        .testimony-section .owl-nav .owl-next span:before {
          font-size: 30px;
          color: rgba(0, 0, 0, 0.2);
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          transition: all 0.3s ease; }
        .testimony-section .owl-nav .owl-prev:hover span:before, .testimony-section .owl-nav .owl-prev:focus span:before,
        .testimony-section .owl-nav .owl-next:hover span:before,
        .testimony-section .owl-nav .owl-next:focus span:before {
          color: #000000; }
      .testimony-section .owl-nav .owl-prev {
        left: 50%;
        margin-left: -80px; }
      .testimony-section .owl-nav .owl-next {
        right: 50%;
        margin-right: -80px; }
    .testimony-section:hover .owl-nav .owl-prev,
    .testimony-section:hover .owl-nav .owl-next {
      opacity: 1; }
    .testimony-section:hover .owl-nav .owl-prev {
      left: 50%;
      margin-left: -80px; }
    .testimony-section:hover .owl-nav .owl-next {
      right: 50%;
      margin-right: -80px; }
    .testimony-section .owl-dots {
      text-align: center; }
      .testimony-section .owl-dots .owl-dot {
        width: 10px;
        height: 10px;
        margin: 5px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.2); }
        .testimony-section .owl-dots .owl-dot.active {
          background: #1b9ce3; }
  
  .testimony-wrap {
    display: block;
    position: relative;
    background: rgba(255, 255, 255, 0.1);
    color: rgba(0, 0, 0, 0.8);
    border: 3px solid #d1ebf9;
    padding: 20px; }
    .testimony-wrap .user-img {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      position: relative; }
    .testimony-wrap .text {
      width: calc(100% - 90px);
      position: relative; }
    .testimony-wrap .quote {
      position: absolute;
      top: -45px;
      left: 25px;
      opacity: 1; }
      .testimony-wrap .quote i {
        font-size: 40px;
        color: #1b9ce3; }
    .testimony-wrap .name {
      font-weight: 400;
      font-size: 18px;
      margin-bottom: 0;
      color: #000000; }
    .testimony-wrap .position {
      font-size: 14px;
      color: #1b9ce3; }
  
  .image-popup {
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in; }
  
  .mfp-with-zoom .mfp-container,
  .mfp-with-zoom.mfp-bg {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
  
  .mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1; }
  
  .mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8; }
  
  .mfp-with-zoom.mfp-removing .mfp-container,
  .mfp-with-zoom.mfp-removing.mfp-bg {
    opacity: 0; }
  
  .video {
    width: 100%;
    position: relative; }
  
  .icon-video {
    width: 80px;
    height: 80px;
    background: #fff;
    -webkit-box-shadow: 0px 0px 34px -8px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 34px -8px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 34px -8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    margin: 0 auto;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%; }
    .icon-video span {
      font-size: 30px;
      color: #1b9ce3; }
  
  .ftco-intro {
    position: relative;
    padding: 3em 0;
    z-index: 0; }
    .ftco-intro:after {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      content: '';
      z-index: -1;
      opacity: .8;
      background: #233e62;
      background: #1566ad;
      background: -moz-linear-gradient(left, #1566ad 0%, #1b9de3 100%);
      background: -webkit-gradient(left top, right top, color-stop(0%, #1566ad), color-stop(100%, #1b9de3));
      background: -webkit-linear-gradient(left, #1566ad 0%, #1b9de3 100%);
      background: -o-linear-gradient(left, #1566ad 0%, #1b9de3 100%);
      background: -ms-linear-gradient(left, #1566ad 0%, #1b9de3 100%);
      background: -webkit-gradient(linear, left top, right top, from(#1566ad), to(#1b9de3));
      background: linear-gradient(to right, #1566ad 0%, #1b9de3 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1566ad', endColorstr='#1b9de3', GradientType=1 ); }
    .ftco-intro .btn {
      width: 100%; }
    .ftco-intro h2 {
      line-height: 1.2; }
  
  #section-counter {
    position: relative;
    z-index: 0;
    padding-bottom: 1em; }
  
  .wrapper {
    width: 100%;
    max-width: 1110px;
    background: #fff;
    padding: 30px;
    -webkit-box-shadow: 0px 5px 18px -9px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0px 5px 18px -9px rgba(0, 0, 0, 0.14);
    box-shadow: 0px 5px 18px -9px rgba(0, 0, 0, 0.14); }
  
  .ftco-counter {
    overflow-x: hidden;
    position: relative;
    margin-top: -50px; }
    @media (max-width: 1199.98px) {
      .ftco-counter {
        background-position: top center !important; } }
    .ftco-counter .img-video {
      width: 100%;
      display: block; }
      @media (max-width: 767.98px) {
        .ftco-counter .img-video {
          height: 300px; } }
    .ftco-counter .icon span {
      font-size: 50px;
      color: #000000;
      line-height: 1; }
    .ftco-counter .block-18 {
      display: block;
      width: 100%; }
    .ftco-counter .text strong.number {
      font-weight: 600;
      font-size: 40px;
      color: #000000;
      display: block;
      position: relative; }
      .ftco-counter .text strong.number:after {
        position: absolute;
        bottom: 5px;
        left: 0;
        content: '';
        width: 35px;
        height: 2px;
        background: #1b9ce3; }
    .ftco-counter .text span {
      display: block;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.8);
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 800; }
    @media (max-width: 767.98px) {
      .ftco-counter .counter-wrap {
        margin-bottom: 20px; } }
  
  .ftco-consult {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    position: relative; }
    @media (max-width: 991.98px) {
      .ftco-consult {
        background-position: top center !important; } }
    .ftco-consult .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      opacity: .8;
      background: #1566ad;
      background: -moz-linear-gradient(left, #1566ad 0%, #1b9de3 100%);
      background: -webkit-gradient(left top, right top, color-stop(0%, #1566ad), color-stop(100%, #1b9de3));
      background: -webkit-linear-gradient(left, #1566ad 0%, #1b9de3 100%);
      background: -o-linear-gradient(left, #1566ad 0%, #1b9de3 100%);
      background: -ms-linear-gradient(left, #1566ad 0%, #1b9de3 100%);
      background: -webkit-gradient(linear, left top, right top, from(#1566ad), to(#1b9de3));
      background: linear-gradient(to right, #1566ad 0%, #1b9de3 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1566ad', endColorstr='#1b9de3', GradientType=1 ); }
  
  .appointment-form .form-group {
    position: relative;
    width: 100%;
    display: block; }
    .appointment-form .form-group .icon {
      position: absolute;
      top: 50% !important;
      right: 0;
      font-size: 16px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%); }
      .appointment-form .form-group .icon span {
        color: white !important; }
    .appointment-form .form-group .select-wrap {
      position: relative; }
      .appointment-form .form-group .select-wrap select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; }
    .appointment-form .form-group .btn {
      width: 100%;
      display: block !important; }
  
  .appointment-form .form-control {
    border: transparent !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
    height: 58px !important;
    padding-left: 0;
    padding-right: 0;
    background: transparent !important;
    color: rgba(255, 255, 255, 0.9) !important;
    font-size: 16px;
    border-radius: 0px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
    .appointment-form .form-control::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: rgba(255, 255, 255, 0.9); }
    .appointment-form .form-control::-moz-placeholder {
      /* Firefox 19+ */
      color: rgba(255, 255, 255, 0.9); }
    .appointment-form .form-control:-ms-input-placeholder {
      /* IE 10+ */
      color: rgba(255, 255, 255, 0.9); }
    .appointment-form .form-control:-moz-placeholder {
      /* Firefox 18- */
      color: rgba(255, 255, 255, 0.9); }
    .appointment-form .form-control:focus, .appointment-form .form-control:active {
      border-color: #fff; }
  
  .appointment-form textarea.form-control {
    height: inherit !important; }
  
  .block-23 ul {
    padding: 0; }
    .block-23 ul li, .block-23 ul li > a {
      display: table;
      line-height: 1.5;
      margin-bottom: 15px; }
    .block-23 ul li span {
      color: rgba(255, 255, 255, 0.7); }
    .block-23 ul li .icon svg, .block-23 ul li .text {
      display: table-cell;
      vertical-align: top; }
    .block-23 ul li .icon {
      width: 40px;
      padding-right: 40px;
      font-size: 18px;
      padding-top: 2px;
      color: white; }
  
  .contact-section .contact-info p a {
    color: #1a1a1a; }
  
  .contact-section .contact-info p span {
    display: block;
    font-size: 18px;
    font-weight: 600;
    color: #000000; }
  
  .contact-section .contact-info .icon span {
    font-size: 20px; }
  
  @media (min-width: 992px) {
    .contact-section .contact-info .border-height {
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      border-right: 1px solid rgba(0, 0, 0, 0.1); } }
  
  .contact-section .contact-form {
    width: 100%; }
  
  .block-9 h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 40px; }
  
  .block-9 .form-control {
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 15px; }
  
  #map {
    height: 600px; }
  
  .block-21 .blog-img {
    display: block;
    height: 80px;
    width: 80px; }
  
  .block-21 .text {
    width: calc(100% - 100px); }
    .block-21 .text .heading {
      font-size: 18px;
      font-weight: 400; }
      .block-21 .text .heading a {
        color: #000000; }
        .block-21 .text .heading a:hover, .block-21 .text .heading a:active, .block-21 .text .heading a:focus {
          color: #1b9ce3; }
    .block-21 .text .meta > div {
      display: inline-block;
      font-size: 12px;
      margin-right: 5px; }
      .block-21 .text .meta > div a {
        color: gray; }
  
  /* Blog*/
  
  .tagcloud a {
    text-transform: uppercase;
    display: inline-block;
    padding: 4px 10px;
    margin-bottom: 7px;
    margin-right: 4px;
    border-radius: 4px;
    color: #000000;
    border: 1px solid #ccc;
    font-size: 11px; }
    .tagcloud a:hover {
      border: 1px solid #000; }
  
  .comment-form-wrap {
    clear: both; }
  
  .comment-list {
    padding: 0;
    margin: 0; }
    .comment-list .children {
      padding: 50px 0 0 40px;
      margin: 0;
      float: left;
      width: 100%; }
    .comment-list li {
      padding: 0;
      margin: 0 0 30px 0;
      float: left;
      width: 100%;
      clear: both;
      list-style: none; }
      .comment-list li .vcard {
        width: 80px;
        float: left; }
        .comment-list li .vcard img {
          width: 50px;
          border-radius: 50%; }
      .comment-list li .comment-body {
        float: right;
        width: calc(100% - 80px); }
        .comment-list li .comment-body h3 {
          font-size: 18px;
          font-weight: 500; }
        .comment-list li .comment-body .meta {
          text-transform: uppercase;
          font-size: 13px;
          letter-spacing: .1em;
          color: #ccc; }
        .comment-list li .comment-body .reply {
          padding: 5px 10px;
          background: #e6e6e6;
          color: #000000;
          text-transform: uppercase;
          font-size: 11px;
          letter-spacing: .1em;
          font-weight: 400;
          border-radius: 4px; }
          .comment-list li .comment-body .reply:hover {
            color: #fff;
            background: black; }
  
  .search-form {
    background: #fafafa;
    padding: 10px; }
    .search-form .form-group {
      position: relative;
      margin-bottom: 0; }
      .search-form .form-group input {
        padding-right: 50px;
        font-size: 14px; }
    .search-form .icon {
      position: absolute;
      top: 50%;
      right: 20px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%); }
  
  .subscribe-form .form-group {
    position: relative;
    margin-bottom: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0; }
    .subscribe-form .form-group input {
      background: transparent !important;
      border: 1px solid transparent;
      color: rgba(255, 255, 255, 0.7) !important;
      font-size: 16px;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      border-radius: 0; }
      .subscribe-form .form-group input::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(255, 255, 255, 0.7) !important; }
      .subscribe-form .form-group input::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(255, 255, 255, 0.7) !important; }
      .subscribe-form .form-group input:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(255, 255, 255, 0.7) !important; }
      .subscribe-form .form-group input:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(255, 255, 255, 0.7) !important; }
    .subscribe-form .form-group .form-control {
      border: 1px solid rgba(255, 255, 255, 0.4); }
    .subscribe-form .form-group .submit {
      color: #000000 !important;
      display: block;
      width: 100%;
      height: 52px;
      font-size: 16px;
      background: #fff !important;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      border-radius: 0; }
      .subscribe-form .form-group .submit:hover {
        cursor: pointer; }
  
  .subscribe-form .icon {
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: rgba(255, 255, 255, 0.8); }
  
  @media (max-width: 991.98px) {
    .sidebar {
      margin-top: 5em; } }
  
  .sidebar-box {
    margin-bottom: 30px;
    padding: 0 25px 25px;
    font-size: 15px;
    width: 100%; }
    .sidebar-box h3 {
      font-size: 18px;
      margin-bottom: 20px; }
  
  .categories {
    padding: 0; }
    .categories li {
      position: relative;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #dee2e6;
      list-style: none; }
      .categories li:last-child {
        margin-bottom: 0;
        border-bottom: none;
        padding-bottom: 0; }
      .categories li a {
        display: block; }
        .categories li a span {
          position: absolute;
          right: 0;
          top: 0;
          color: #ccc; }
      .categories li.active a {
        color: #000000;
        font-style: italic; }
  
  #ftco-loader {
    position: fixed;
    width: 96px;
    height: 96px;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.9);
    -webkit-box-shadow: 0px 24px 64px rgba(0, 0, 0, 0.24);
    box-shadow: 0px 24px 64px rgba(0, 0, 0, 0.24);
    border-radius: 16px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity .2s ease-out, visibility 0s linear .2s;
    -o-transition: opacity .2s ease-out, visibility 0s linear .2s;
    transition: opacity .2s ease-out, visibility 0s linear .2s;
    z-index: 1000; }
  
  #ftco-loader.fullscreen {
    padding: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    background-color: #fff;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none; }
  
  #ftco-loader.show {
    -webkit-transition: opacity .4s ease-out, visibility 0s linear 0s;
    -o-transition: opacity .4s ease-out, visibility 0s linear 0s;
    transition: opacity .4s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1; }
  
  #ftco-loader .circular {
    -webkit-animation: loader-rotate 2s linear infinite;
    animation: loader-rotate 2s linear infinite;
    position: absolute;
    left: calc(50% - 24px);
    top: calc(50% - 24px);
    display: block;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  
  #ftco-loader .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    -webkit-animation: loader-dash 1.5s ease-in-out infinite;
    animation: loader-dash 1.5s ease-in-out infinite;
    stroke-linecap: round; }
  
  @keyframes loader-rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg); } }
  
  @keyframes loader-dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0; }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px; }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -136px; } }